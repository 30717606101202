<template>
  <CustomModal v-model="showMailSettingsModal" @confirm="confirm" @cancel="cancel">
    <template v-slot:title>{{ $t('mailsettings.title') }}</template>
    <div class="form">
      <div class="row">
        <div class="label">{{ $t('mailsettings.labelHost') }}:</div>
        <div class="value">
          <input type="text" v-model="settings.host"/>
          <br/><div v-if="errorHost != null" class="fieldError">{{errorHost}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label">{{ $t('mailsettings.labelPort') }}:</div>
        <div class="value">
          <input type="number" v-model="settings.port"/>
          <br/><div v-if="errorPort != null" class="fieldError">{{errorPort}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label">{{ $t('mailsettings.labelUsername') }}:</div>
        <div class="value">
          <input type="text" v-model="settings.username"/>
          <br/><div v-if="errorUsername != null" class="fieldError">{{errorUsername}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label">{{ $t('mailsettings.labelPassword') }}:</div>
        <div class="value">
          <input type="password" v-model="settings.password"/>
          <br/><div v-if="errorPassword != null" class="fieldError">{{errorPassword}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label">{{ $t('mailsettings.labelFromAddress') }}:</div>
        <div class="value">
          <input type="text" v-model="settings.fromAddress"/>
          <br/><div v-if="errorFromAddress != null" class="fieldError">{{errorFromAddress}}</div>
        </div>
      </div>
    </div>
    <div class="securityHint">
      {{$t('mailsettings.securityHint')}}
    </div>
  </CustomModal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import CustomModal from '@/components/modals/CustomModal.vue';
import CryptoHelper from '@/helpers/CryptoHelper';
import ServiceHelper from '@/helpers/ServiceHelper';

export default {
  name: 'MailSettingsModal',
  props: [],
  components: {
    CustomModal,
  },
  data() {
    return {
      showMailSettingsModal: false,
      errorHost: null,
      errorPort: null,
      errorUsername: null,
      errorPassword: null,
      errorFromAddress: null,
      settings: {
        port: 587,
        ssl: true,
      },
    };
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    show() {
      this.errorHost = null;
      this.errorPort = null;
      this.errorUsername = null;
      this.errorPassword = null;
      this.errorFromAddress = null;
      this.showMailSettingsModal = true;
      this.tenant = this.$store.getters.tenant;
      if (this.tenant != null && this.tenant.mailSettings != null) {
        try {
          const dec = CryptoHelper.decrypt(this.tenant.mailSettings);
          this.settings = JSON.parse(dec);
        } catch (e) {
          console.error(e);
        }
      }
    },
    hide() {
      this.showMailSettingsModal = false;
    },
    cancel() {
      this.hide();
    },
    isEmptyStr(s) {
      return s == null || s.trim().length === 0;
    },
    async confirm() {
      this.errorHost = this.isEmptyStr(this.settings.host) ? this.$t('mailsettings.error.host') : null;
      this.errorPort = this.settings.port == null || this.settings.port === 0 ? this.$t('mailsettings.error.port') : null;
      this.errorUsername = this.isEmptyStr(this.settings.username) ? this.$t('mailsettings.error.username') : null;
      this.errorPassword = this.isEmptyStr(this.settings.password) ? this.$t('mailsettings.error.password') : null;
      this.errorFromAddress = this.isEmptyStr(this.settings.fromAddress) ? this.$t('mailsettings.error.fromAddress') : null;

      if (this.errorHost != null || this.errorPort != null || this.errorUsername != null || this.errorPassword != null || this.errorFromAddress != null) {
        return;
      }

      try {
        await ServiceHelper.sendDataToBackend('mailsettings', { mailSettings: CryptoHelper.encrypt(JSON.stringify(this.settings)) }, 'PUT');
        this.tenant.mailSettings = this.settings;
        this.hide();
      } catch (e) {
        window.alert(e);
      }
    },
  },
};
</script>

<style scoped>
.securityHint {
  font-weight: 800;
  color: black;
  background-color: #f4995433;
  font-size: 16px;
  padding: 8px;
  margin-right: 32px;
  margin-top: 32px;
}
</style>
