<template>
  <div class="settings">
      <div class="page-title">{{ $t('pageTitle.settings') }}</div>
      <div class="settings-wrapper" ref="settingsWrapper" :style='{ height: settingsHeight }'>
        <div class="tiles-wrapper" >
          <router-link to="/users">
            <div class="tile">
              <div class="tile-icon"><i class="fas fa-user"/></div>
              <div class="tile-name">{{ $t('settings.users') }} </div>
            </div>
          </router-link>
          <router-link to="/usergroups">
            <div class="tile">
              <div class="tile-icon"><i class="fas fa-users"/></div>
              <div class="tile-name">{{ $t('settings.usergroups') }} </div>
            </div>
          </router-link>
          <router-link to="/devices">
            <div class="tile">
              <div class="tile-icon"><i class="fas fa-mobile-alt"/></div>
              <div class="tile-name">{{ $t('settings.devices') }} </div>
            </div>
          </router-link>
          <router-link to="/daymessages">
            <div class="tile">
              <div class="tile-icon"><i class="fas fa-bullhorn"/></div>
              <div class="tile-name">{{ $t('settings.daymessages') }} </div>
            </div>
          </router-link>
          <!--
          <router-link to="/references">
            <div class="tile">
              <div class="tile-icon"><i class="fas fa-circle"/></div>
              <div class="tile-name">{{ $t('settings.references') }} </div>
            </div>
          </router-link>
          -->
          <router-link to="/invoicing">
            <div class="tile">
              <div class="tile-icon"><i class="fas fa-money-bill"/></div>
              <div class="tile-name">{{ $t('settings.invoicing') }} </div>
            </div>
          </router-link>
          <div to="/mailsettings">
            <div class="tile" @click="openMailSettings()">
              <div class="tile-icon"><i class="fas fa-cogs"/></div>
              <div class="tile-name">{{ $t('settings.mailsettings') }} </div>
            </div>
          </div>
          <br/>
        </div>
        <div class="button-wrapper">
          <div v-if="$store.getters.userIsAdmin" class="actionButton refresh delete-tenant" @click="deleteTenant()"><i class="fas fa-trash"/>&nbsp;{{ $t("action.deleteTenant") }}</div>
        </div>
      </div>
    <ConfirmModal ref="confirmDeleteTenantModal" :title="$t('modal.titleDeleteTenant')" :text="$t('modal.textDeleteTenant')"/>
    <PromptModal ref="promptTenantNameModal" :title="$t('modal.titleDeleteTenant')" :text="$t('modal.textConfirmTenantNameForDeletion').replace('§1', $store.getters.tenant.tenantId)"/>
    <MailSettingsModal ref="mailSettingsModal"/>
  </div>
</template>

<script>
/* eslint-disable no-console, no-alert */
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import PromptModal from '@/components/modals/PromptModal.vue';
import MailSettingsModal from '@/components/modals/MailSettingsModal.vue';
import ServiceHelper from '../helpers/ServiceHelper';

export default {
  name: 'SettingsView',
  components: {
    ConfirmModal,
    PromptModal,
    MailSettingsModal,
  },
  props: [],
  async mounted() {
    const storedConnectionInfo = localStorage.getItem('col_ci');
    if (storedConnectionInfo != null) {
      this.connectionInfo = storedConnectionInfo;
      this.tenantId = ServiceHelper.getTenant();
    }
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  data() {
    return {
      connectionInfo: null,
      ciPlaintext: '',
      settingsHeight: '400px',
      tenantId: null,
    };
  },
  methods: {
    resizeHandler() {
      this.settingsHeight = `${(window.innerHeight - this.$refs.settingsWrapper.offsetTop - 10)}px`;
    },
    async deleteTenant() {
      if (this.$store.getters.userIsAdmin) {
        if (await this.$refs.confirmDeleteTenantModal.show()) {
          this.promptForConfirmation();
        }
      }
    },
    async promptForConfirmation() {
      const tenantName = await this.$refs.promptTenantNameModal.show();
      if (tenantName == null || tenantName.trim() === '') return;
      if (tenantName === this.$store.getters.tenant.tenantId) {
        const url = `tenant/${this.$store.getters.tenant.tenantId}`;
        await ServiceHelper.deleteDataFromBackend(url);
        localStorage.removeItem('col_log_in');
        localStorage.removeItem('col_user');
        localStorage.removeItem('col_tenant');
        window.location.href = '/';
      } else {
        alert(this.$t('error.wrongTenantName'));
        setTimeout(this.promptForConfirmation, 250);
      }
    },
    openMailSettings() {
      this.$refs.mailSettingsModal.show();
    },
  },
};
</script>
<style scoped>
@import url('../styles/tiles.module.css');

.tiles-wrapper {
  padding-bottom: 0px;
}
.settings-wrapper {
  overflow-y: auto;
}

.button-wrapper {
  text-align: left;
  margin: 16px 0px;
}

.delete-tenant {
  background-color: --var(color_orange);
  float: none;
}
</style>
